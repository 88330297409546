<template>
  <div class="layout-config h-100 p-10">
    <Row type="flex" :gutter="10">
      <Col span="4">
        <Input
          v-model="param.query"
          placeholder="请输入智能教室名称"
          clearable
        />
      </Col>
      <Col span="4">
        <Button type="primary" @click="getData()">查询</Button>
        <Button type="success" class="m-l-5" @click="showModal">新增</Button>
      </Col>
    </Row>
    <Table
      border
      :columns="cols"
      :data="table.data"
      ref="table"
      class="m-t-10"
    />
    <Page
      :page="param.current"
      @on-change="getData"
      :page-size="10"
      :total="table.total"
      show-total
      class="text-r m-t-10"
    />
  </div>
</template>

<script>
import Vue from "vue";
import iView from "view-design";
import {
  getClassroom,
  getSmartClassroomByUserId,
  addSmartClassroom,
  deleteSmartClassroom,
  getOrgSimpleById,
} from "@/libs/api/smartClassroom";
export default {
  data() {
    const _this = this;
    return {
      param: {
        current: 1,
        size: 10,
        order: "classroomName",
      },
      table: {},
      classroomName: "",
      warningTxt: null,
      loading: true,
      modal: {
        show: false,
        loading: false,
        data: {},
        rules: {},
      },
      orgNames: {},
      cols: [
        {
          title: "序号",
          type: "index",
          key: "index",
          width: 70,
          align: "center",
        },
        { title: "智能教室名称", key: "classroomName" },
        { title: "创建时间", key: "createTime" },
        {
          title: "所属机构",
          render(h, w) {
            const btn = h(
              "Button",
              {
                props: { type: "info", size: "small" },
                style: { display: w.row.orgName ? 'none' : 'block' },
                on: {
                  click() {
                    if (!w.row.orgName) {
                      getOrgSimpleById(w.row.orgId).then(res => {
                        Vue.set(_this.table.data, w.row._index, Object.assign(w.row, { orgName: res.orgName }))
                      })
                    }
                  },
                },
              },
              "查看"
            );
            const div = h('div', { style: { display: !w.row.orgName ? 'none' : 'block' } }, w.row.orgName)
            return !w.row.orgName ? btn : div
          },
        },
        {
          title: "操作",
          width: 260,
          render(h, w) {
            const btns = [];
            btns.push(
              h(
                "Button",
                {
                  props: { type: "primary", size: "small" },
                  on: {
                    click() {
                      _this.$router.push({
                        path:
                          "/config/intelligentControl/smartClassroom/smartDevices",
                        query: {
                          classroomName: w.row.classroomName,
                          classroomId: w.row.classroomId,
                        },
                      });
                    },
                  },
                },
                "智能设备"
              )
            );
            btns.push(
              h(
                "Button",
                {
                  props: { type: "info", size: "small" },
                  on: {
                    click() {

                      _this.$router.push({
                        path:
                          "/config/intelligentControl/smartClassroom/smartScene",
                        query: {
                          classroomName: w.row.classroomName,
                          classroomId: w.row.classroomId,
                        },
                      });
                    },
                  },
                },
                "智能场景"
              )
            );
            // btns.push(
            //   h('Button', {
            //     props: { type: 'success', size: 'small' },
            //     on: {
            //       click() {
            //         _this.classroomName = w.row.classroomName
            //         _this.showModal(w.row.classroomId)
            //       }
            //     }
            //   }, '编辑')
            // )
            btns.push(
              h(
                "Button",
                {
                  props: { type: "error", size: "small" },
                  on: {
                    click() {
                      _this.$Modal.confirm({
                        title: "删除",
                        content: `确定删除此教室--[${w.row.classroomName}]吗？`,
                        onOk: () => {
                          deleteSmartClassroom({
                            classroom_id: w.row.classroomId,
                          }).then((res) => _this.getData());
                        },
                      });
                    },
                  },
                },
                "删除"
              )
            );
            return btns;
          },
        },
      ],
    };
  },
  methods: {
    showModal(classroomId) {
      const _this = this;
      this.$Modal.confirm({
        title: "智能教室名称：",
        loading: true,
        render: (h) => {
          return [
            h("Input", {
              props: {
                value: this.classroomName,
                placeholder: "请输入智能教室名称...",
              },
              on: {
                input: (val) => {
                  this.classroomName = val;
                  this.warningTxt = null;
                },
              },
            }),
            h(
              "p",
              { domProps: { className: "m-t-5 f-12 c-red" } },
              this.warningTxt
            ),
          ];
        },
        onOk: function() {
          if (!_this.classroomName) {
            _this.warningTxt = "*请输入智能教室名称!";
            setTimeout(() => {
              this.buttonLoading = false;
            }, 1000);
            return;
          }
          let params = {};
          if (classroomId) {
            params = {
              classroomId,
            };
          }

          addSmartClassroom({
            classroomName: _this.classroomName,
            orgId: _this.$store.state.user.organization.organizationId,
            ownerId: _this.$store.state.user.userId,
            ...params,
          }).then((res) => {
            if (res.code == "HA0200") {
              _this.classroomName = null;
              iView.Modal.remove();
              _this.getData();
            } else {
              _this.$Message.error(res.msg || "添加失败！");
            }
          });
        },
        onCancel: () => {
          this.classroomName = null;
          this.warningTxt = null;
        },
      });
    },
    // 获取数据列表
    getData(page) {
      Object.assign(this.param, { current: page ? page : 1 });
      getClassroom(this.param).then((res) => {
        res.code == "HA0200"
          ? (this.table = res)
          : this.$Message.error(res.msg);
      });
    },
    getClassroomByUser() {
      getSmartClassroomByUserId({
        user_id: this.$store.state.user.userId,
      }).then((res) => {
        res.code == "HA0200"
          ? (this.table = res)
          : this.$Message.error(res.msg);
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.smart-classroom-warp {
}
</style>
